import {
  ContentModule,
  ModuleProperties,
  WithHtmlId,
  useAnimationStates,
  useShowInstructions,
} from '@backstage-components/base';
import {
  ButtonProps,
  Button as ChakraButton,
  Link,
  LinkProps,
} from '@chakra-ui/react';
import {SerializedStyles, css} from '@emotion/react';
import {MotionProps as FramerMotionProps, motion} from 'framer-motion';
import {useSubscription} from 'observable-hooks';
import {FC} from 'react';
import {
  ComponentDefinition,
  SchemaType,
  reactName as namespace,
} from './ButtonDefinition';
import {sharedBtnStyles} from './ButtonStyles';

type MotionProps = Pick<
  FramerMotionProps,
  'animate' | 'initial' | 'onAnimationComplete' | 'variants'
>;

interface SharedProps extends ModuleProperties, SchemaType {
  ariaLabel?: string;
  /**
   * @default false
   */
  disabled?: boolean;
  /**
   * @default false
   */
  isLoading?: boolean;
  motionProps?: MotionProps;
  onBlur?: () => void;
  onClick?: () => void;
  onFocus?: () => void;
  onHover?: () => void;
}

interface HtmlLinkProps {
  /**
   * @default false
   */
  isExternal?: boolean;
  href: string;
}

interface HtmlButtonProps {
  /**
   * @default 'button'
   */
  htmlType?: 'button' | 'submit' | 'reset';
}

export type IButtonProps = (HtmlButtonProps | HtmlLinkProps) &
  SharedProps & {
    cssProp?: SerializedStyles;
    ignorePointerEvents?: boolean;
  };

export type ButtonComponentDefinition = ContentModule<
  'Button',
  IButtonProps & SchemaType
>;

// framer motion components for Chakra UI
const MotionBtn = motion<ButtonProps>(ChakraButton);
const MotionLink = motion<ButtonProps & Pick<LinkProps, 'href' | 'isExternal'>>(
  ChakraButton
);

const Button: FC<IButtonProps & WithHtmlId & ButtonProps> = (props) => {
  const {
    ariaLabel,
    children,
    disabled = false,
    isLoading = false,
    motionProps,
    id,
    ignorePointerEvents,
  } = props;

  return 'href' in props ? (
    <MotionLink
      id={id}
      as={Link}
      href={props?.href}
      isExternal={props.isExternal}
      onClick={props.onClick}
      onFocus={props.onFocus}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      aria-label={
        ariaLabel || (typeof children === 'string' ? children : 'Link')
      }
      {...sharedBtnStyles({...props, ignorePointerEvents})}
      {...motionProps}
    >
      {children}
    </MotionLink>
  ) : (
    <MotionBtn
      id={id}
      onBlur={props.onBlur}
      onClick={props.onClick}
      onFocus={props.onFocus}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      type={props.htmlType ?? 'button'}
      isLoading={isLoading}
      isDisabled={isLoading || disabled}
      aria-label={
        ariaLabel || (typeof children === 'string' ? children : 'Button')
      }
      {...sharedBtnStyles({...props, ignorePointerEvents})}
      {...motionProps}
    >
      {children}
    </MotionBtn>
  );
};

export const ButtonComponent: FC<ButtonComponentDefinition> = (definition) => {
  const {props, id, config} = definition;
  const {animationStates} = props;

  const styles = css`
    ${definition.style}
    ${definition.props.styleAttr}
  `;

  const {observable, broadcast} = useShowInstructions(
    ComponentDefinition.instructions,
    definition
  );
  const motionOptions = useAnimationStates(
    observable,
    broadcast,
    animationStates
  );

  const onClick = (): void => {
    if ('onClick' in props && typeof props.onClick === 'function') {
      props.onClick();
    }

    broadcast({type: `${namespace}:on-click`, meta: {}});
  };

  useSubscription(observable, (inst) => {
    if (inst.type === `${namespace}:click`) {
      onClick();
    }
  });

  return (
    <Button
      {...props}
      onClick={onClick}
      onFocus={() => broadcast({type: 'Button:on-focus', meta: {}})}
      onMouseEnter={() => broadcast({type: 'Button:on-mouseenter', meta: {}})}
      onMouseLeave={() => broadcast({type: 'Button:on-mouseleave', meta: {}})}
      ignorePointerEvents={config.scope === 'admin'}
      cssProp={styles}
      id={id}
      motionProps={motionOptions}
    />
  );
};
